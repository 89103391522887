.wrapper {
  min-height: 90vh;
  height: 100%;
  background-color: #1d0b18;
  position: relative;
  background-image: url("../../images/background/Music_Desktop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (max-width: 576px) {
  .wrapper {
    background-attachment: scroll;
  }
}
.image {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
  display: block;
}
.sliderWrapper {
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 30px;
}
.mySlider {
  position: relative;
  width: 100%;
  max-width: 1000px;
  padding: 0 60px;
  margin: 0 auto;
  display: block;
  min-height: 100px;
}
.slides {
  width: 100%;
}
.shopify {
  width: 400px;
  margin-top: 30px;
  margin-bottom: 40px;
  margin-left: 10%;
}
.display-inline {
  display: flex;
  flex-direction: row;
  padding-left: 10%;
}
.listenOnSpotify {
  margin-right: 0px;
  height: 30px;
  padding-left: 10%;
  margin-bottom: 40px;
}
.listenOnAppleMusic {
  margin-left: 10px;
  height: 30px;
  margin-bottom: 40px;
}
.listenWrapper {
  display: flex;
  justify-content:left;
}
.iframeContainer {
  position: relative;
  width: 35%;
  height: 0;
  margin-bottom: 30px;
  margin-left: 10%;
  margin-right: 1%;
  min-height: 400px;
}
@media only screen and (max-width: 576px) {
  .iframeContainer {
    margin-bottom: 0px;
  }
}
.iframeContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  border: none;
}
.swiperButtonPrevs,
.swiperButtonNexts {
  color: #cdd0d8;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    background-position: 60%;
  }
}
@media only screen and (max-width: 766px) {
  .wrapper {
    background-image: url("../../images/background/Music_Mobile.jpg");
    padding-bottom: 200px;
    background-position: 80%;
  }
  .iframeContainer {
    width: 60%;
  }
}
@media only screen and (max-width: 575px) {
  .swiperButtonPrevs::after,
  .swiperButtonNexts::after {
    font-size: 28px;
  }
  .wrapper {
    min-height: 100vh;
  }
  .iframeContainer {
    width: 80%;
    margin-bottom: 20px;
  }
  .shopify {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .listenOnSpotify {
    margin-bottom: 200px;
  }
  .listenOnAppleMusic {
    margin-bottom: 200px;
  }
}
@media only screen and (max-width: 400px) {
  .mySlider {
    padding: 0 40px;
  }
  .swiperButtonPrevs::after,
  .swiperButtonNexts::after {
    font-size: 20px;
  }
}
