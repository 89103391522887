.sideImage {
  position: absolute;
  bottom: 40px;
  left: 30px;
  z-index: 1;
  width: 12px;
}
@media only screen and (max-width: 576px) {
  .sideImage {
    left: 15px;
    width: 10px;
    bottom: 60px;
  }
}
